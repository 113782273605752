* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

a:hover {
  transition: all 0.3s ease !important;
  transform: scale(1.1) !important;
  cursor: pointer;
}

button:hover {
  transition: all 0.3s ease !important;
  transform: scale(1.1) !important;
  cursor: pointer;
}

h1 {
  font-size: 2.5rem; /* 40px */
}
h2 {
  font-size: 2rem; /* 32px */
}
h3 {
  font-size: 1.75rem; /* 28px */
}
h4 {
  font-size: 1.5rem; /* 24px */
}
h5 {
  font-size: 1.25rem; /* 20px */
}
h6 {
  font-size: 1rem; /* 16px */
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
}

.swiper-button-prev > svg,
.swiper-button-next > svg {
  color: white !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "" !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}

p {
  font-size: 1rem; /* 16px */
}

.vip {
  border: 3px solid #dbb070;
}
.a {
  border: 3px solid #c95440;
}
.b {
  border: 3px solid #545454;
}
.c {
  border: 3px solid #00bf63;
}
.vipInput {
  border: 2px solid #dbb070;
}

.aInput {
  border: 2px solid #c95440;
}
.bInput {
  border: 2px solid #545454;
}
.cInput {
  border: 2px solid #00bf63;
}

.vipLabel {
  background-color: #dbb070;
}

.aLabel {
  background-color: #c95440;
}
.bLabel {
  background-color: #545454;
}
.cLabel {
  background-color: #00bf63;
}

.paginationWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}
.pagination {
  list-style: none;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
}
.active {
  border: 1px solid black;
  border-radius: 7px;
}
.pageItem {
  list-style: none;

  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  border-radius: 7px;
}
.pageItem a {
  padding: 2px 12px;
}
.pageItem:hover {
  background-color: black;
  color: white;
  border-radius: 7px;
}
@media (max-width: 768px) {
  h1 {
    font-size: 2rem; /* 32px */
  }
  h2 {
    font-size: 1.75rem; /* 28px */
  }
  h3 {
    font-size: 1.5rem; /* 24px */
  }
  h4 {
    font-size: 1.25rem; /* 20px */
  }
  h5 {
    font-size: 1rem; /* 16px */
  }
  h6 {
    font-size: 0.875rem; /* 14px */
  }
}
@media screen and (max-width: 500px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px !important;
  }
}
